define(['dropdownInputFactory', 'app'], (dropdownInputFactory, app) => {
  const addLabels = (choices) => {
    if (choices.length <= 0) return [];

    const firstLetter = choices[0].display[0];
    const sameFirstLetterUntilIndex = (() => {
      const i = choices.findIndex(({ display }) => display[0].toUpperCase() !== firstLetter.toUpperCase());
      return i <= 0? choices.length: i;
    })();

    return [
      { type: 'label', display: firstLetter.toUpperCase() },
      ...choices.slice(0, sameFirstLetterUntilIndex),
      ...addLabels(choices.slice(sameFirstLetterUntilIndex)),
    ];
  };

  return dropdownInputFactory({
    initialValue: ({ element }) => {
      const rawChoicesJSON = element.getAttribute('data-choices');
      const rawChoices = JSON.parse(rawChoicesJSON);
      const selectedValue = rawChoices.find( choice => choice.selected === 'true' );
      return selectedValue ? selectedValue.display : '';
    },
    getChoices: (inputValueSoFar, { element }) => {
      const rawChoicesJSON = element.getAttribute('data-choices');
      const rawChoices = JSON.parse(rawChoicesJSON);
      rawChoices.sort((choice1, choice2) => choice1.display.localeCompare(choice2.display));
      const choices = rawChoices.filter( ({display}) => display !== "" && display.toLowerCase().includes(inputValueSoFar.toLowerCase()))
        .map(({display, value}) => ({ type: 'choice', display, value}));
      return inputValueSoFar.length <= 0? addLabels(choices): choices;
    },
  })
});
